<template>
  <div class="text-left">
    <BaseHeader :title="'Writer Payments'"></BaseHeader>

    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <section v-else class="pt-4">
      <div class="row" v-if="pendingTotals && pendingTotals.length > 0">
        <div
          class="col col-12 col-sm-6"
          v-for="(item, index) in pendingTotals"
          :key="`${index}pts`"
        >
          <v-alert
            class="rounded rounded-xl"
            color="info"
            prominent
            dense
            text
            type="info"
          >
            <h2 class="font-weight-bold">$ {{ item.total || 0.0 }}</h2>
            <span class="text-capitalize">
              {{ Object.keys(item)[0] || "" }}
            </span>
          </v-alert>
        </div>
      </div>

      <v-alert dense text type="info">
        Note: Payments are grouped bi-monthly
      </v-alert>

      <div class="my-4">
        <h4>Pending</h4>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in pendingPayments" :key="i">
            <v-expansion-panel-header>
              <strong class="text-uppercase">{{ item.year }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <!-- month -->
              <v-expansion-panels>
                <v-expansion-panel
                  v-for="(month, i) in item.months"
                  :key="i + 'month'"
                >
                  <v-expansion-panel-header>
                    <strong class="text-uppercase">{{ month.name }}</strong>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- half month -->
                    <v-expansion-panels>
                      <v-expansion-panel
                        v-for="(payout, i) in month.payouts"
                        :key="i + 'payout'"
                      >
                        <span v-if="payout.data && payout.data.length > 1">
                          <v-expansion-panel-header>
                            <strong class="text-uppercase">{{
                              payout.title
                            }}</strong>
                          </v-expansion-panel-header>
                          <v-expansion-panel-content>
                            <!-- datatable -->
                            <v-data-table
                              v-if="payout.data && payout.data.length > 0"
                              :mobile-breakpoint="0"
                              :headers="pendingPaymentsHeaders"
                              :items="payout.data"
                              :search="pending_search"
                              :show-select="
                                userType != userTypes.writer ? true : false
                              "
                              v-model="selected"
                              item-key="id"
                              :hide-default-footer="true"
                              :disable-pagination="true"
                            >
                              <template v-slot:[`item.order_id`]="{ item }">
                                <router-link
                                  :to="{
                                    name: 'Order',
                                    params: { id: item.order_id },
                                  }"
                                  >{{ item.order_id || "--" }}</router-link
                                >
                              </template>
                              <template v-slot:[`item.status`]="{ item }">
                                <v-chip
                                  v-if="item.status != ''"
                                  outlined
                                  color="primary"
                                  small
                                >
                                  {{ item.status || "" }}
                                </v-chip>
                              </template>
                              <template v-slot:[`item.writer_names`]="{ item }">
                                <router-link
                                  :to="{
                                    name: 'WriterProfile',
                                    params: { id: item.writer_id || '' },
                                  }"
                                  >{{ item.writer_names || "--" }}</router-link
                                >
                              </template>
                              <template v-slot:[`item.amount`]="{ item }">
                                ${{ item.amount || "--" }}
                              </template>
                              <template
                                v-slot:[`item.amount_per_page`]="{ item }"
                              >
                                ${{ item.amount_per_page || "--" }}
                              </template>
                            </v-data-table>
                            <div v-else>
                              <p class="text-center primary--text">
                                No pending payments
                              </p>
                            </div>
                            <!-- /datatable -->
                            <div
                              class="card border-0 mx-4 my-4 bg-white shadow"
                              v-if="
                                selected.length > 0 &&
                                userType != userTypes.writer
                              "
                            >
                              <div class="card-body">
                                <h5 class="text-uppercase">With Selected</h5>
                                <button
                                  @click="markAsPaid"
                                  class="btn btn-primary"
                                >
                                  Mark as Paid
                                </button>
                              </div>
                            </div>
                          </v-expansion-panel-content>
                        </span>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- /half month -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- /month -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>

      <div class="my-4">
        <h4>Paid</h4>
        <v-expansion-panels>
          <v-expansion-panel v-for="(item, i) in yearMonths" :key="i">
            <v-expansion-panel-header>
              <strong class="text-uppercase font-weight-bold">{{
                item.year
              }}</strong>
            </v-expansion-panel-header>
            <v-expansion-panel-content class="bg-">
              <!-- month -->
              <v-expansion-panels flat>
                <v-expansion-panel
                  v-for="(month, i) in item.months"
                  :key="i + 'month'"
                >
                  <v-expansion-panel-header>
                    <strong class="text-uppercase primary--text">{{
                      month.name
                    }}</strong>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <!-- half month -->
                    <v-expansion-panels flat inset>
                      <v-expansion-panel
                        v-for="(payout, i) in month.payouts"
                        :key="i + 'payout'"
                        class="data-container"
                      >
                        <v-expansion-panel-header
                          @click="getPaymentData(payout.dates)"
                        >
                          <strong class="text-uppercase font-weight-normal">{{
                            payout.title
                          }}</strong>
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <!-- pending datatable  -->
                          <BaseLoading
                            style="min-height: 240px"
                            v-if="loading_paid"
                          ></BaseLoading>

                          <span v-else>
                            <v-data-table
                              v-if="paidPayments.length > 0"
                              :mobile-breakpoint="0"
                              :headers="paidPaymentsHeaders"
                              :items="paidPayments"
                              :search="paid_search"
                              :show-select="
                                userType != userTypes.writer ? true : false
                              "
                              v-model="paid_selected"
                              item-key="id"
                              :disable-pagination="true"
                              :hide-default-footer="true"
                            >
                              <template v-slot:[`item.order_id`]="{ item }">
                                <router-link
                                  :to="{
                                    name: 'Order',
                                    params: { id: item.order_id },
                                  }"
                                  >{{ item.order_id || "--" }}</router-link
                                >
                              </template>

                              <template v-slot:[`item.status`]="{ item }">
                                <v-chip outlined color="primary" small v-if="item.status != ''">
                                  {{ item.status || "" }}
                                </v-chip>
                              </template>
                              <template v-slot:[`item.writer_names`]="{ item }">
                                <router-link
                                  :to="{
                                    name: 'WriterProfile',
                                    params: { id: item.writer_id || '' },
                                  }"
                                  >{{ item.writer_names || "--" }}</router-link
                                >
                              </template>
                              <template v-slot:[`item.amount`]="{ item }">
                                ${{ item.amount || "--" }}
                              </template>
                              <template
                                v-slot:[`item.amount_per_page`]="{ item }"
                              >
                                ${{ item.amount_per_page || "--" }}
                              </template>
                            </v-data-table>

                            <div v-else>
                              <p class="text-center primary--text">
                                No paid payments
                              </p>
                            </div>
                          </span>

                          <div
                            class="card mx-4 border-0 mt-4 bg-white shadow"
                            v-if="
                              paid_selected.length > 0 &&
                              userType != userTypes.writer
                            "
                          >
                            <div class="card-body">
                              <h5 class="text-uppercase">With Selected</h5>
                              <button
                                @click="markAsUnpaid"
                                class="btn btn-danger"
                              >
                                Mark as Unpaid
                              </button>
                            </div>
                          </div>

                          <!-- /pending datatable  -->
                        </v-expansion-panel-content>
                      </v-expansion-panel>
                    </v-expansion-panels>
                    <!-- /half month -->
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <!-- /month -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import Alerts from "../../../mixins/alerts";
import http from "../../modules/http.index";
export default {
  name: "Payments",
  computed: {
    ...mapState("payment", [
      "pendingTotals",
      "pendingPayments",
      "loading",
      "pendingPaymentWriters",
      "pendingPaymentsHeaders",
      "paidPaymentsHeaders",
    ]),
    ...mapGetters("auth", ["userType", "userTypes"]),
  },

  data() {
    return {
      paidPayments: [],
      loading_paid: true,
      writer_id: "",
      selected: [],
      paid_selected: [],
      writer_list: [],
      type: "all",
      pending_search: "",
      paid_search: "",
      yearMonths: [],
      activePayout: "",
    };
  },

  methods: {
    ...mapActions("payment", [
      "_getPendingPayments",
      "_getPaidPayments",
      "_markAsPaid",
      "_markAsUnpaid",
    ]),
    async getPaymentData(payout) {
      if (this.activePayout !== payout[1]) {
        this.loading_paid = true;
        try {
          let data = {
            start_date: payout[0],
            end_date: payout[1],
          };
          let res = await http.post(
            `/Payments/GetPaidDataByDate/${this.$route.params.id}`,
            data
          );
          this.activePayout = payout[1];
          this.paidPayments = res.data.Data;
          console.log("res", res);
        } catch (err) {
          console.log("err", err);
        } finally {
          this.loading_paid = false;
        }
      }
    },
    markAsPaid() {
      if (this.userType === this.userTypes.writer) {
        this.errorNoti("Operation not authorized!");
        return;
      }
      let data = this.selected.map((item) => item.id);
      this._markAsPaid(data)
        .then((res) => this.successNoti(res || "Payments marked as paid!"))
        .catch((err) => this.errorNoti(err));
    },
    markAsUnpaid() {
      if (this.userType === this.userTypes.writer) {
        this.errorNoti("Operation not authorized!");
        return;
      }
      let data = this.paid_selected.map((item) => item.id);
      this._markAsUnpaid(data)
        .then((res) => this.successNoti(res || "Payments marked as unpaid!"))
        .catch((err) => this.errorNoti(err));
    },
  },

  async mounted() {
    try {
      let res = await http.get("/Payments/GetYearsAndMonths/");
      this.yearMonths = res.data.Data;
      await this._getPendingPayments(this.$route.params.id);
      // await this._getPaidPayments(this.$route.params.id);
    } catch (error) {
      console.log(error);
    }
  },

  mixins: [Alerts],
};
</script>

<style lang="scss" scoped>
.search {
  max-width: 300px;
}
</style>
